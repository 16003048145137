import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";

import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";

import light from "../components/ui/Theme";

function Layout({ children }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);

  return (
    <ThemeProvider theme={responsiveFontSizes(light)}>
      <Header
        value={value}
        setValue={setValue}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}

        // toggleSidebar={toggleSidebar}
      />
      {/* <Slidebar isOpen={isOpen} toggleSidebar={toggleSidebar} /> */}
      {children}
      <Footer />
    </ThemeProvider>
  );
}

export default Layout;
