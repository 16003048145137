import React from "react";
import { FaFacebookSquare, FaLinkedin, FaInstagram } from "react-icons/fa";

const data = [
  {
    id: 1,
    icon: (
      <FaFacebookSquare
        size={60}
        color="#4267B2"
        className="social-icon"
      ></FaFacebookSquare>
    ),
    url: "https://www.facebook.com/ScalableAds",
  },
  {
    id: 2,
    icon: (
      <FaInstagram
        size={60}
        color="#DD2A7B"
        className="social-icon"
      ></FaInstagram>
    ),
    url: "https://www.linkedin.com/company/10962841/",
  },
  {
    id: 3,
    icon: (
      <FaLinkedin size={60} color="2867B2" className="social-icon"></FaLinkedin>
    ),
    url: "https://www.linkedin.com/company/10962841/",
  },
];
const links = data.map((link) => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link">
        {link.icon}
      </a>
    </li>
  );
});

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  );
};
