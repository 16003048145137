import React from "react";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegEnvelope,
  FaHeadset,
} from "react-icons/fa";
import { Typography, Button } from "@material-ui/core";
import { Link } from "gatsby";
// import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";

const data = [
  {
    id: 1,
    icon: (
      <FaMapMarkerAlt
        color="#FF5B84"
        size={20}
        className="contact-icon"
      ></FaMapMarkerAlt>
    ),
    text: "Vila N 13, Rte Institut pasteur , Chéraga",
  },
  // {
  //   id: 2,
  //   icon: (
  //     <FaPhoneAlt
  //       color="#FF5B84"
  //       size={20}
  //       className="contact-icon"
  //     ></FaPhoneAlt>
  //   ),
  //   text: "0556 12 65 93 / 0770 16 67 34",
  // },
  {
    id: 3,
    icon: (
      <FaRegEnvelope
        color="#FF5B84"
        size={20}
        className="contact-icon"
      ></FaRegEnvelope>
    ),
    text: "contact@scalable-ads.com",
  },
  // {
  //   id: 4,
  //   icon: (
  //     <FaHeadset color="#FF5B84" size={20} className="contact-icon"></FaHeadset>
  //   ),
  //   text: "Hotline: Disponible du Dimanche au jeudi De 10H00 À 17h00",
  // },
  {
    id: 5,
    icon: "",
    text: (
      <Button
        startIcon={<FaMapMarkerAlt />}
        component={Button}
        href="https://maps.app.goo.gl/rojMwiMTsDQNPTDP6"
        style={{ margin: "20px auto" }}
        variant="contained"
        color="primary"
        size="large"
      >
        Localisation google maps
      </Button>
    ),
  },
];
const contactInfo = data.map((link, index) => {
  return (
    <li key={index} style={{}} className="contact-info">
      {link.icon}

      <Typography component="p" className="contact-text">
        {link.text}
      </Typography>
    </li>
  );
});

export default ({ styleClass }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ul className={`contacts-links ${styleClass ? styleClass : ""}`}>
        {contactInfo}
      </ul>
    </div>
  );
};
