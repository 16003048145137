import React from "react";
import SocialLinks from "../constants/socialLinks";
import ContactInfo from "../constants/contactInfo";
import Typography from "@material-ui/core/Typography";

function Footer() {
  return (
    <footer className="footer">
      <section className="footer1">
        <div>
          <Typography component="h4" variant="h5">
            Qui Sommes-Nous ?
          </Typography>
          <Typography component="p">
            Scalable est une agence spécialisée dans la publicité digitale,
            notre mission est d’accompagner les marques et entreprises dans la
            mise en place et le pilotage des campagnes publicitaires afin de
            leurs permettre d’avoir la meilleure visibilité sur internet.
          </Typography>
        </div>
        <div>
          <Typography component="h4" variant="h5">
            Contactez-Nous
          </Typography>
          <ContactInfo />
        </div>
        <div>
          <Typography component="h4" variant="h5">
            Suivez-Nous Sur :
          </Typography>
          <SocialLinks styleClass="footer-links" />
        </div>
      </section>
      <section className="footer2">
        <Typography component="p">
          © Copyright {new Date().getFullYear()}. Tous droits réservés.{" "}
          <span>Scalable</span>
        </Typography>
      </section>
    </footer>
  );
}

export default Footer;
